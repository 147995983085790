<script setup lang="ts">
import { ref } from 'vue'
import { Form, FormField } from '@primevue/forms'
import { zodResolver } from '@primevue/forms/resolvers/zod'
import type { FormSubmitEvent } from '@primevue/forms'

import Button from 'primevue/button'
import IftaLabel from 'primevue/iftalabel'
import InputText from 'primevue/inputtext'
import Popover from 'primevue/popover'
import Message from 'primevue/message'

import { IconLinkPlus } from '@tabler/icons-vue'

import zod from 'zod'

import trpc from '../lib/trpc'

const formOpen = ref(false)
const popover = ref<any>()

const emit = defineEmits<{
  shouldRefresh: []
}>()

const resolver = zodResolver(
  zod.object({
    url: zod.string().url()
  })
)

const watchForLinkScrape = async (linkId: string) => {
  const { link } = await trpc.link.get.query(linkId)
  if (!link.status_code) {
    setTimeout(watchForLinkScrape, 1_000, linkId)
    return
  }
  emit('shouldRefresh')
}
const submitForm = async (event: FormSubmitEvent) => {
  const newLink: string = event.values.url

  const linkId = await trpc.link.add.mutate(newLink)
  emit('shouldRefresh')
  watchForLinkScrape(linkId)

  formOpen.value = false
  popover.value?.hide()
}
</script>

<template>
  <Button @click="popover?.toggle($event)" label="Add Link" severity="contrast">
    <template #icon><IconLinkPlus size="20" /></template>
    Add Link
  </Button>

  <Popover ref="popover">
    <Form v-slot="$form" :resolver @submit="submitForm" :validateOnValueUpdate="false">
      <FormField>
        <IftaLabel>
          <InputText name="url" />
          <label>New Link</label>
        </IftaLabel>
        <Message v-if="($form as any).url?.invalid" severity="error" size="small">
          That doesn't look like a valid link (URL).
        </Message>
      </FormField>
      <Button type="submit" label="Save Link" />
    </Form>
  </Popover>
</template>
