import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import superjson from 'superjson'

import router from '../router'
import session, { pullSession } from './session'

import type { AppRouter } from 'server'

const client = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: '/trpc',
      fetch: async (...args) => {
        try {
          const response = await fetch(...args)
          if (response.status === 403) {
            router.push({ name: 'login' })
            throw new Error('Received a 403, redirecting to login...')
          }
          return response
        } finally {
          session.value = pullSession
        }
      }
    })
  ],
  transformer: superjson
})
export default client
